const EmptyString = '';
// -----------------------------------------------------------------------------
export function or(value, defaultValue) {
    return value || defaultValue;
}
export function orArr(value) {
    return or(value, []);
}
export function orObj(value) {
    return or(value, {});
}
export function orEmptyString(value) {
    return String(or(value, EmptyString));
}
export function or0(value) {
    return or(value, 0);
}
export function orNotDefined(value, defaultValue) {
    return value !== undefined && value !== null ? value : defaultValue;
}
// -----------------------------------------------------------------------------
export function thisIf(truthy, value, otherwise) {
    return truthy ? value : otherwise;
}
export function ifNot(truthy, value) {
    return truthy ? undefined : value;
}
