export const lightModeTheme = {
    backgroundColor: '#FFF',
    foregroundColor: '#333',
    sidebarBackgroundColor: '#f7f7f7',
    sidebarCreatorColor: '#00F',
};
// -----------------------------------------------------------------------------
export const darkModeTheme = {
    backgroundColor: '#36393F',
    foregroundColor: '#ccc',
    sidebarBackgroundColor: '#2F3135',
    sidebarCreatorColor: '#AFF',
};
