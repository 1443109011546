import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { render } from 'react-dom';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { StoreContext } from 'storeon/react';
import Store from 'store';
import ErrorBoundary from 'Component/ErrorBoundary';
import EditorScreen from 'Component/Screen/Editor';
import HomeScreen from 'Component/Screen/Home';
import NotFoundScreen from 'Component/Screen/NotFound';
import Layout from 'Component/Layout';
// -----------------------------------------------------------------------------
export default function renderApp() {
    render(_jsx(App, {}, void 0), document.body);
    // remove loader components
    const components = document.querySelectorAll('[data-preloader="true"]');
    [].forEach.call(components, (component) => component.parentNode.removeChild(component));
}
// -----------------------------------------------------------------------------
function App() {
    return (_jsx(HashRouter, { children: _jsx(StoreContext.Provider, { value: Store, children: _jsx(Layout, { children: _jsx(ErrorBoundary, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(HomeScreen, {}, void 0) }, void 0), _jsx(Route, { path: "/playground/:id", element: _jsx(EditorScreen, {}, void 0) }, void 0), _jsx(Route, { path: "/playground", element: _jsx(EditorScreen, {}, void 0) }, void 0), _jsx(Route, { path: "*", element: _jsx(NotFoundScreen, {}, void 0) }, void 0)] }, void 0) }, void 0) }, void 0) }, void 0) }, void 0));
}
