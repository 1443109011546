import { jsx as _jsx } from "react/jsx-runtime";
import { memo } from 'react';
import styled from 'styled-components';
// -----------------------------------------------------------------------------
const NotFoundScreen = memo(function NotFoundScreen() {
    return (_jsx(Container, { children: _jsx(Content, { children: _jsx("h1", { children: "Page Not Found" }, void 0) }, void 0) }, void 0));
});
export default NotFoundScreen;
// -----------------------------------------------------------------------------
const Container = styled.div `
	flex-grow: 1;
	padding: 5vw;
	padding-top: max(52px, 5vw);
`;
const Content = styled.div `
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 512px;
	width: 100%;
`;
