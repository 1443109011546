import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo } from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import useTheme from 'effect/use_theme';
import Header from './Header';
import PassageLibrary from './PassageLibrary';
import Sidebar from './Sidebar';
// -----------------------------------------------------------------------------
const GlobalStyle = createGlobalStyle `
	* {
		transition: all 250ms;
		box-sizing: border-box;
		font-family: ${(props) => props.theme.font || 'Copse'}, serif;
	}

	html, body {
		height: 100%;
		margin: 0;
	}

	body {
		background: ${(props) => props.theme.backgroundColor};
		color: ${(props) => props.theme.foregroundColor};
	}
  `;
// -----------------------------------------------------------------------------
const Layout = memo(function Layout({ children }) {
    const theme = useTheme();
    //
    return (_jsxs(ThemeProvider, { theme: theme, children: [_jsx(GlobalStyle, {}, void 0), _jsxs(Container, { children: [_jsx(Header, {}, void 0), _jsx(Sidebar, {}, void 0), _jsx(PassageLibrary, {}, void 0), children] }, void 0)] }, void 0));
});
export default Layout;
// -----------------------------------------------------------------------------
const Container = styled.div `
	align-content: flex-start;
	display: flex;
	height: 100%;
`;
