import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { thisIf } from 'lib/or';
// -----------------------------------------------------------------------------
export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }
    render() {
        const { children } = this.props;
        const { hasError } = this.state;
        //
        return thisIf(hasError, _jsx(DefaultErrorMessage, {}, void 0), children);
    }
}
// -----------------------------------------------------------------------------
function DefaultErrorMessage() {
    return _jsx("div", { children: "There was an error loading part of the website" }, void 0);
}
