import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import { hidePassages } from 'store/action/passages';
import { posts } from 'data/posts';
import LeftArrowIcon from 'Component/Icon/LeftArrow';
import EyeglassIcon from 'Component/Icon/Eyeglass';
import MenuButton from 'Component/MenuButton';
import Section from 'Component/Section';
const PassageLibrary = memo(function PassageLibrary() {
    const { passages_show: passagesShow } = useStoreon('passages_show');
    const containerRef = useRef();
    const [searchTermsRaw, setSearchTermsRaw] = useState('');
    const [collapsedCategory, setCollapsedCategory] = useState({});
    const searchTerms = searchTermsRaw.length > 0
        ? (searchTermsRaw || '').toLocaleLowerCase().trim().split(/\ +/g)
        : undefined;
    const filteredPosts = posts.filter(post => {
        if (!searchTerms)
            return true;
        return searchTerms.every(term => post.author.toLowerCase().includes(term) ||
            post.title.toLowerCase().includes(term));
    });
    const organizedPosts = filteredPosts.reduce((acc, post) => {
        const key = post.author;
        if (!acc[key])
            acc[key] = [];
        acc[key].push(post);
        return acc;
    }, {});
    const sections = Object.keys(organizedPosts).sort();
    sections.forEach(key => {
        organizedPosts[key] = sortBy(organizedPosts[key], ['title']);
    });
    return (_jsx(Container, { hide: !passagesShow, onClick: (e) => {
            if (e.target !== containerRef.current)
                return;
            hidePassages();
        }, ref: containerRef, children: _jsxs(Content, { children: [_jsx(Section, { children: _jsx(CloseButton, { children: _jsxs(MenuButton, { onClick: hidePassages, children: [_jsx(LeftArrowIcon, {}, void 0), " PRACTICE LIBRARY"] }, void 0) }, void 0) }, void 0), _jsx(Section, { children: _jsx(SearchContainer, { children: _jsxs(SearchInput, { children: [_jsx(EyeglassIcon, {}, void 0), _jsx("input", { type: "search", placeholder: "Search...", value: searchTermsRaw, onChange: (e) => setSearchTermsRaw(e.target.value), "data-dontstealfocus": "1" }, void 0)] }, void 0) }, void 0) }, void 0), _jsx(Section, { grow: true, children: sections.map((section) => {
                        const entries = organizedPosts[section] || [];
                        const isCollapsed = collapsedCategory[section];
                        return entries.length ? (_jsxs(Category, { children: [_jsxs(CategoryHeader, { collapsed: isCollapsed, onClick: () => setCollapsedCategory((oldValue) => ({
                                        ...oldValue,
                                        [section]: !collapsedCategory[section],
                                    })), children: [_jsx("span", { children: section }, void 0), _jsxs(PostCount, { children: [entries.length, " posts"] }, void 0), _jsx(LeftArrowIcon, {}, void 0)] }, void 0), _jsx(CategoryEntries, { collapsed: isCollapsed, children: entries.map((entry) => (_jsxs(Entry, { to: `/playground/${entry.id}`, onClick: hidePassages, children: [_jsx(EntryTitle, { children: entry.title }, void 0), _jsxs(EntryMeta, { children: [_jsx(EntryCategory, { children: entry.category }, void 0), _jsx(EntryLink, { href: entry.url, target: "_blank", rel: "noopener noreferrer", onClick: e => e.stopPropagation(), children: "Original Post \u2197" }, void 0)] }, void 0)] }, entry.id))) }, void 0)] }, section)) : null;
                    }) }, void 0)] }, void 0) }, void 0));
});
export default PassageLibrary;
const Container = styled.div.attrs((props) => ({ hide: props.hide })) `
    bottom: 0;
    left: ${(props) => (props.hide ? '333px' : '0')};
    opacity: ${(props) => (props.hide ? '0' : '1')};
    pointer-events: ${(props) => (props.hide ? 'none' : 'all')};
    position: fixed;
    right: ${(props) => (props.hide ? '-333px' : '0')};
    top: 0;
    user-select: none;
    z-index: 1000;
`;
const Content = styled.div `
    background: ${(props) => props.theme.sidebarBackgroundColor};
    color: ${(props) => props.theme.foregroundColor};
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    overflow-y: auto;
    padding: 16px;
    width: 333px;
    height: 100%;
    margin-left: auto;
`;
const CloseButton = styled.div `
    align-items: center;
    cursor: pointer;
    display: flex;
    letter-spacing: 1px;

    & button {
        height: 100%;
    }

    & svg {
        height: 16px;
        margin-right: 8px;
    }
`;
const SearchContainer = styled.div `
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
const SearchInput = styled.div `
    align-items: center;
    background: ${(props) => props.theme.backgroundColor};
    border: 1px ${(props) => props.theme.foregroundColor} solid;
    border-radius: 4px;
    display: flex;
    height: 32px;
    position: relative;

    & svg {
        flex-shrink: 0;
        height: 16px;
        margin-left: 8px;
        pointer-events: none;
    }

    & input {
        appearance: none;
        background: transparent;
        caret-color: ${(props) => props.theme.foregroundColor};
        color: ${(props) => props.theme.foregroundColor};
        border: 0;
        flex-grow: 1;
        font-size: 1.1rem;
        height: 100%;
        left: 0;
        padding-left: 32px;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;
const Category = styled.div `
    & + & {
        margin-top: 24px;
    }
`;
const CategoryHeader = styled.div.attrs((props) => ({
    collapsed: props.collapsed,
})) `
    align-items: center;
    display: flex;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.2s ease;

    &:hover {
        background: ${props => props.theme.foregroundColor}10;
    }

    & span {
        flex-grow: 1;
        font-weight: 500;
    }

    & svg {
        flex-shrink: 0;
        flex-grow: 0;
        height: 16px;
        transform: rotate(${(props) => (props.collapsed ? '90deg' : '-90deg')});
        margin-left: 8px;
    }
`;
const PostCount = styled.span `
    font-size: 0.8rem;
    opacity: 0.6;
    margin-right: 8px;
`;
const CategoryEntries = styled.div.attrs((props) => ({
    collapsed: props.collapsed,
})) `
    display: flex;
    flex-direction: column;
    height: ${(props) => (props.collapsed ? '0' : '100%')};
    overflow: hidden;
    transition: height 250ms;
`;
const Entry = styled(Link) `
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme.foregroundColor};
    cursor: pointer;
    font-size: 1.1rem;
    padding: 12px 12px 12px 24px;
    position: relative;
    text-align: left;
    text-decoration: none;
    border-radius: 4px;
    margin: 2px 0;
    transition: background-color 0.2s ease;

    &:hover {
        background: ${props => props.theme.foregroundColor}10;
    }
`;
const EntryTitle = styled.span `
    font-size: 1rem;
    margin-bottom: 4px;
`;
const EntryMeta = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    opacity: 0.7;
`;
const EntryCategory = styled.span `
    font-style: italic;
`;
const EntryLink = styled.a `
    color: ${(props) => props.theme.foregroundColor};
    text-decoration: none;
    margin-left: 16px;
    
    &:hover {
        text-decoration: underline;
    }
`;
