import { FontSet } from 'store/key/font';
import { PassagesShow } from 'store/key/passages';
import { SettingsSet } from 'store/key/settings';
import { SidebarShow } from 'store/key/sidebar';
import { ThemeSet } from 'store/key/theme';
// -----------------------------------------------------------------------------
export default function wireUpEvents(store) {
    store.on(SettingsSet, onSettingsSet);
    store.on(ThemeSet, onThemeSet);
    store.on(FontSet, onFontSet);
    store.on(SidebarShow, onSidebarShow);
    store.on(PassagesShow, onPassagesShow);
}
// -----------------------------------------------------------------------------
function onSettingsSet(store, settings) {
    return { settings };
}
// -----------------------------------------------------------------------------
function onThemeSet(store, theme) {
    document.documentElement.setAttribute('data-theme', theme);
    //
    return { theme };
}
// -----------------------------------------------------------------------------
function onFontSet(store, font) {
    return { font };
}
// -----------------------------------------------------------------------------
function onSidebarShow(store, show) {
    return { sidebar_show: show };
}
// -----------------------------------------------------------------------------
function onPassagesShow(store, show) {
    return { passages_show: show };
}
