import Store from 'store';
import { PassagesShow } from 'store/key/passages';
// -----------------------------------------------------------------------------
export function showPassages() {
    togglePassages(true);
}
export function hidePassages() {
    togglePassages(false);
}
export function togglePassages(show) {
    Store.dispatch(PassagesShow, show);
}
