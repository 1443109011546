import Store from 'store';
import { SidebarShow } from 'store/key/sidebar';
// -----------------------------------------------------------------------------
export function showSidebar() {
    toggleSidebar(true);
}
export function hideSidebar() {
    toggleSidebar(false);
}
export function toggleSidebar(show) {
    Store.dispatch(SidebarShow, show);
}
