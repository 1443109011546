export const NotAlphanumericRegex = /[^a-z0-9]/i;
export function getCurrentLine(contentToCopy, node) {
    if (!contentToCopy || !node)
        return '';
    const lines = contentToCopy.split('\n');
    const lineIndex = getCurrentLineIndex(node);
    return lines[lineIndex] || '';
}
export function getCurrentLineIndex(node) {
    var _a;
    if (!((_a = node === null || node === void 0 ? void 0 : node.parentNode) === null || _a === void 0 ? void 0 : _a.childNodes))
        return 0;
    let lineIndex = 0;
    let offset = 0;
    let lastChild = null;
    for (let i = 0; i < node.parentNode.childNodes.length; i++) {
        const child = node.parentNode.childNodes[i];
        if (child.textContent === '\n' && (lastChild === null || lastChild === void 0 ? void 0 : lastChild.textContent) === '\n') {
            offset += 1;
        }
        if (child === node) {
            return lineIndex - offset;
        }
        lastChild = child;
        lineIndex += 1;
    }
    return 0;
}
export function wouldCreateConsecutiveSpaces(writer) {
    var _a, _b;
    if (!writer)
        return false;
    const selection = window.getSelection();
    if (!selection)
        return false;
    const range = selection.getRangeAt(0);
    if (!range)
        return false;
    const index = range.startContainer === writer
        ? ((_b = (_a = writer.lastChild) === null || _a === void 0 ? void 0 : _a.textContent) === null || _b === void 0 ? void 0 : _b.length) || 0
        : range.startOffset;
    const node = range.startContainer === writer ? writer.lastChild : range.startContainer;
    const line = (node === null || node === void 0 ? void 0 : node.textContent) || '';
    return line[index - 1] === ' ' || line[index] === ' ';
}
export function getPreviousSpace(text, offset) {
    if (!text)
        return 0;
    const index = text.lastIndexOf(' ', offset);
    return index < 0 ? 0 : index + 1;
}
export function getNextSpace(text, offset) {
    if (!text)
        return 0;
    const index = text.indexOf(' ', offset);
    return index < 0 ? text.length : index;
}
export function wrapWordsInSpans(text) {
    return text.split(/(\s+)/).map((part, index) => {
        if (part.trim().length === 0)
            return part;
        return `<span class="word" data-offset="${index}">${part}</span>`;
    }).join('');
}
export function getWordStartPosition(text, wordElement) {
    const words = text.split(/(\s+)/);
    const offset = parseInt(wordElement.dataset.offset || '0', 10);
    let position = 0;
    for (let i = 0; i < offset; i++) {
        position += words[i].length;
    }
    return position;
}
